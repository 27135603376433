import tw, { styled } from "twin.macro"

export const Content = tw.div`
  w-full
`

export const Prev = styled.button`
  ${tw`absolute z-50 top-1/2 left-0-8 text-white`}
  &.swiper-button-disabled {
    ${tw`opacity-50`}
  }
`

export const Next = styled.button`
  ${tw`absolute z-50 top-1/2 right-0-8 text-white`}
  &.swiper-button-disabled {
    ${tw`opacity-50`}
  }
`
