import React from "react"

export const withMobileImages = Component => ({
  name = "MobileImages",
  media,
}) => {
  Component.displayName = name

  return <Component media={media} />
}
