import tw, { styled } from "twin.macro"
import { Icon } from "../../Icon/Icon"
import { SmallBody } from "../../Styled/Text"

export const Outer = tw.div`
  flex flex-row items-center mb-4
`

export const Inner = tw.div`
  flex flex-row items-center
`

export const Title = styled(SmallBody)`
  ${tw`mr-1`}
`

export const StyledIcon = styled(Icon)`
  ${tw``}
`
