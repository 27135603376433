import tw, { styled } from "twin.macro"

export const Outer = styled.div`
  ${tw`hidden md:grid grid-cols-2 gap-1-6`}
  ${({ single }) => (single ? tw`grid-cols-1` : tw`grid-cols-2`)}
`

export const Inner = styled.div`
  ${tw`w-full h-full relative`}
`

export const QuickViewWrapper = tw.div`
  absolute top-2 right-2 cursor-pointer
`
