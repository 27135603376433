import React from "react"
import SwiperCore, { Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

import { withPopup } from "./withPopup"
import { Content, Prev, Next } from "./PopupStyles"
import { Image } from "../../../Image/Image"
import { Icon } from "../../../Icon/Icon"
import { ModalExternal, ModalContentsWithLayout } from "../../../Modal/Modal"
SwiperCore.use([Navigation])

export const Popup = withPopup(({ active, setActive, setSwiper, media }) => (
  <ModalExternal isOpen={active} setIsOpen={setActive}>
    <ModalContentsWithLayout
      layout={"productImages"}
      closeButtonPosition="outside"
    >
      <Content>
        <Prev id={"desktop-prev"} title={"previous image"}>
          <Icon name={"chevronLeft"} width={16} height={28} />
        </Prev>
        <Next id={"desktop-next"} title={"next image"}>
          <Icon name={"chevronRight"} width={16} height={28} />
        </Next>
        <Swiper
          navigation={{
            prevEl: "#desktop-prev",
            nextEl: "#desktop-next",
          }}
          lazy={true}
          preloadImages={false}
          onSwiper={setSwiper}
        >
          {media
            .filter(item => item?.image?.src)
            .map(item => (
              <SwiperSlide key={item.id}>
                <Image image={item?.image} ratio={"15/19"} />
              </SwiperSlide>
            ))}
        </Swiper>
      </Content>
    </ModalContentsWithLayout>
  </ModalExternal>
))
