import tw, { styled } from "twin.macro"

export const Crumbs = styled.nav`
  ${tw`flex flex-wrap items-center justify-start px-2 py-0-4 my-1-6 px-2 md:px-3 max-w-xl mr-auto`}
`
export const Crumb = styled.p`
  ${tw`capitalize text-14 text-navy text-opacity-60`}
  ${({ pointer }) => (pointer ? tw`cursor-pointer` : null)}
  ${({ first }) => (first ? tw`font-medium` : null)}
`

export const Divider = tw.span`
  text-14 text-navy text-opacity-60
`
