import React from "react"
import { FindAStore } from "../../../Header/FindAStore/FindAStore"
import { RichText } from "../../../RichText/RichText"
import { Buttons, Button } from "./ManualOrderStyles"

import { withManualOrder } from "./withManualOrder"

export const ManualOrder = withManualOrder(
  ({ manualOrder }) => (
    <div>
      <RichText>{manualOrder.description}</RichText>
      <div className="h-2"></div>

      <Buttons align="left" marginBottom>      
        { manualOrder.buttons?.map(button => (
          <Button              
            key={button?._key}
            link={button?.link}
            style={button?.style || 'primary'}
            size={button?.size}
          >
            { button?.link.title}
          </Button>
        ))}
        {!manualOrder?.hideFindAStoreButton &&
        <div className="ml-1">
          <FindAStore showMobile={true} />
        </div>}
      </Buttons>     
    </div>  
  ),
)
