import React, { useEffect, useMemo, useState } from "react"
import { useApp } from "../../../../../hooks/useApp"

export const withBundleProduct = Component => ({
                                                 name = "BundleProduct",
                                                 product,
                                                 handleSelectedVariantChange,
                                               }) => {
  const {
    config: {
      settings: { routes },
    },
  } = useApp()
  const [selectedSibling, setSelectedSibling] = useState(product?.group?.[0] || product?.product)
  const [selectedOption, setSelectedOption] = useState(null)

  const selectedVariant = useMemo(() => {
    if (!selectedSibling || !selectedOption) {
      return
    }

    return selectedSibling?.variants?.find(v => v?.selectedOptions?.find(option =>
      option?.name === selectedOption?.name && option?.value === selectedOption?.value
    ))
  }, [selectedOption, selectedSibling])

  useEffect(() => {
    setSelectedOption(null)
  }, [selectedSibling])

  useEffect(() => {
    handleSelectedVariantChange?.(selectedVariant)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVariant])


  Component.displayName = name
  return (
    <Component
      product={product?.product}
      productUrl={`${routes.PRODUCT}/${selectedSibling?.handle}`}
      siblings={product?.group}
      selectedSibling={selectedSibling}
      setSelectedSibling={setSelectedSibling}
      selectedOption={selectedOption}
      setSelectedOption={setSelectedOption}
      handleSelectedVariantChange={handleSelectedVariantChange}
    />
  )
}
