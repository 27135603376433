import React from "react"

import config from "../../../../config.default"

import { useLocation } from "../../../hooks/useLocation"


export const withFindInStore = Component => ({
    name = "FindInStore",
  }) => {
    const { shopifyStore } = useLocation()

    const brauzEndpoint = React.useMemo(() => (
      typeof window !== 'undefined' && window?.location?.pathname?.includes("/products/") && config?.services?.brauz?.endpoint
    ), [])

  Component.displayName = name
  return <Component
    domain={`${shopifyStore}.myshopify.com`} 
    brauzEndpoint={brauzEndpoint}
  />
}
