import React from "react"

import { withDesktopImages } from "./withDesktopImages"
import { Outer, Inner, QuickViewWrapper } from "./DesktopImagesStyles"
import { Image } from "../../../Image/Image"
import { Popup } from "../Popup/Popup"
import { Video } from "../../../Video/Video"
import { Icon } from "../../../Icon/Icon"

export const DesktopImages = withDesktopImages(
  ({
    handleImageClick,
    popupActive,
    setPopupActive,
    clickedSlideIndex,
    media,
  }) => (
    <>
      <Outer single={media.length === 1}>
        {media.map((item, index) =>
          item?.image?.src ? (
            <Inner key={item?.id}>
              <Image
                handleClick={() => handleImageClick(index)}
                image={item?.image}
                ratio={"15/19"}
              />
              {index === 0 && (
                <QuickViewWrapper onClick={() => handleImageClick(index)}>
                  <Icon name="quickView" />
                </QuickViewWrapper>
              )}
            </Inner>
          ) : (
            <Inner key={item?.id}>
              <Video
                url={item?.sources?.[1]?.url}
                image={item?.previewImage}
                ratio={"15/19"}
                autoplay
                loop
              />
            </Inner>
          ),
        )}
      </Outer>
      <Popup
        active={popupActive}
        setActive={setPopupActive}
        media={media}
        clickedSlideIndex={clickedSlideIndex}
      />
    </>
  ),
)
