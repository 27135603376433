import React from "react"

import { withShare } from "./withShare"
import { Title, Outer, Inner, StyledIcon } from "./ShareStyles"

export const Share = withShare(({ title, methods, handleClick }) => (
  <Outer>
    <Title>{title}</Title>
    <Inner>
      {methods.map(method => (
        <div key={method._key} onClick={() => handleClick(method.name)}>
          <StyledIcon
            name={method.name}
            title={method.name}
            width={30}
            height={30}
          />
        </div>
      ))}
    </Inner>
  </Outer>
))
