import React from "react"
import { Link } from "gatsby"

import { Crumbs, Crumb, Divider } from "./BreadCrumbsStyles"

export const BreadCrumbs = ({ paths }) => (
  <Crumbs>
    {paths.map(({ title, url }, index) => (
      <React.Fragment key={title}>
        {index > 0 ? <Divider>&nbsp;/&nbsp;</Divider> : null}
        {url ? (
          <Crumb first={index === 0} as={Link} to={url} pointer title={title}>
            {title}
          </Crumb>
        ) : (
          <Crumb>{title}</Crumb>
        )}
      </React.Fragment>
    ))}
  </Crumbs>
)
