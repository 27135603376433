import tw, { styled } from "twin.macro"
import { Tabs, TabList, Tab, TabPanel } from "@reach/tabs"

import { RichText as SanityRichText } from "../../RichText/RichText"

export const Wrapper = tw.div`
  mt-2-4 md:mt-3 mb-2-4 md:mb-3
`

export const Desktop = tw.div`
  hidden md:block
`

export const Mobile = tw.div`
  md:hidden
`

export const StyledTabs = styled(Tabs)`
  ${tw`border-b border-navy border-opacity-60 mb-3-2`}
`

export const StyledTabList = styled(TabList)`
  ${tw`flex flex-row border-b border-navy border-opacity-60`}
`

export const StyledTab = styled(Tab)`
  ${tw`mr-2-4 py-1-6`}
  ${({ isSelected }) => (isSelected ? tw`border-b-3 border-navy` : tw``)}
`

export const StyledTabPanel = styled(TabPanel)`
  ${tw`pt-2-4 pb-3-2`}
`

export const RichText = styled.div`
  ${tw`text-14 leading-1.78`}
  > *, > div > * {
    ${tw`mb-0-8 last:mb-0`}
  }
  li {
    ${tw`mt-1`}
  }
`

export const IconWrapper = tw.div`
  w-4 max-h-4 mr-2
`

export const Feature = styled.div`
  ${tw`py-1 flex flex-row items-center`}
`

export const Shipping = tw.div`
  hidden md:block mb-3
`

export const OptionalContent = styled(SanityRichText)`
  ${tw`hidden md:block`}
`

export const ProductCode = tw.p`
  block font-semibold text-14 leading-1.42 tracking-2 text-navy text-opacity-60 mb-2
`

export const ProductVendorDescription  = styled.div`
  ${tw`mt-2`}
  a {
    text-decoration: underline;
  }
`