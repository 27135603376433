import React from "react"
import { useApp } from "../../hooks/useApp"

import { useShopify } from "../../hooks/useShopify"

export const withProduct =
  Component =>
  ({
    name = "Product",
    product,
    content,
    template,
    live,
    location,
    loading,
  }) => {
    const {
      config: {
        settings: { routes },
      },
    } = useApp()
    const { edgeNormaliser, productNormaliser } = useShopify()

    let rawProduct = undefined
    if (product.shopify?.raw) {
      try {
        rawProduct = JSON.parse(product.shopify?.raw)
      } catch (e) {
        console.error("Error parsing raw shopify product")
      }
    }

    const item = productNormaliser(live?.product || rawProduct || product)

    const grouped =
      live?.grouped?.edges?.filter?.(edge => {
        const product = edge.node
        return (
          product?.publishedAt &&
          new Date(product?.publishedAt).getTime() < new Date().getTime()
        )
      }) || []

    const siblings = edgeNormaliser({ edges: grouped })

    const { settingShowRelatedProducts, templateAdditionalSections } =
      template || {}

    const {
      related: relatedProducts,
      primaryCollection,
      collection: relatedCollection,
      productAdditionalSections,
      manualOrder,
    } = edgeNormaliser(content)[0]

    const recommendations = live?.recommendations?.filter?.(product => {
      return (
        product?.publishedAt &&
        new Date(product?.publishedAt).getTime() < new Date().getTime()
      )
    })

    React.useEffect(() => {
      // redirect if gift card giftcard is in the title
      if (item?.title?.toLowerCase()?.replace(/ /g, "")?.includes("giftcard")) {
        window.location.href = "/giftcard"
      }
    }, [item])

    const parentCollections = React.useMemo(() => {
      if (!location?.state?.collections && !primaryCollection) return null
      return location?.state?.collections?.length > 0
        ? location?.state?.collections
        : [primaryCollection]
    }, [location, primaryCollection])

    const paths =
      parentCollections?.length > 0
        ? parentCollections.map(collection => ({
            title: collection.title,
            url: `${routes.COLLECTION}/${
              collection?.handle || collection?.shopify?.shopifyHandle
            }`,
          }))
        : []

    Component.displayName = name
    return (
      <Component
        product={item}
        bundle={
          product?.bundle?.trigger?.[0]?._type ===
          "promotionTriggerSpecificQuantityProductByProduct"
            ? product?.bundle
            : null
        }
        bundleSubtitle={product?.bundleSubtitle}
        bundleProductsTitle={product?.bundleProductsTitle}
        siblings={siblings}
        loading={loading}
        settingShowRelatedProducts={settingShowRelatedProducts}
        relatedProducts={relatedProducts}
        relatedCollection={relatedCollection}
        recommendations={recommendations}
        productAdditionalSections={productAdditionalSections}
        templateAdditionalSections={templateAdditionalSections}
        paths={paths}
        manualOrder={manualOrder}
      />
    )
  }
