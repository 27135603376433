import React from "react"

import { withRelated } from "./withRelated"
import { Wrapper } from "./RelatedStyles"
import { ProductsCarousel } from "../../Sections/ProductsCarousel/ProductsCarousel"

export const Related = withRelated(
  ({ title, recommendations, relatedProducts, relatedCollection }) =>
    recommendations?.length > 0 ||
    relatedProducts?.length > 0 ||
    relatedCollection?.shopify?.shopifyHandle?.length > 0 ? (
      <Wrapper collapse="<md">
        <ProductsCarousel
          products={relatedProducts || recommendations}
          collection={relatedCollection}
          title={title}
          layout={"normal"}
          id={"related"}
        />
      </Wrapper>
    ) : null,
)
