import React, { useCallback, useEffect, useMemo, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useShopify } from "../../../../hooks/useShopify"
import { useCheckoutContext } from "../../../../hooks/useCheckout"
import { useCart } from "../../../../hooks/useCart"
import { EmphasisText } from "./BundleStyles"

export const withBundleForm = Component => ({
                                              name = "BundleForm",
                                              descriptionHtml,
                                              bundle,
                                              bundleSubtitle,
                                              bundleProductsTitle,
                                            }) => {
  const { checkout } = useCheckoutContext()
  const {
    getGroupedProducts,
  } = useShopify()
  const { addAllToCart, loading } = useCart()
  const [products, setProducts] = useState(null)
  const [formattedPrice, setFormattedPrice] = useState(null)
  const [formattedDiscountedPrice, setFormattedDiscountedPrice] = useState(null)
  const [salePercentage, setSalePercentage] = useState(null)
  const [selectedVariants, setSelectedVariants] = useState({})

  const { productTemplateData } = useStaticQuery(graphql`
    query SANITY_TEMPLATE_BUNDLE_PRODUCT_INFORMATION {
      productTemplateData: sanityTemplateProduct {
        selectSizeButtonText
        selectSizesButtonText
        addToCartButtonText
        settingsShippingLink: _rawSettingsShippingLink(
          resolveReferences: { maxDepth: 2 }
        )
      }
    }
  `)

  const {
    settingsShippingLink,
  } = productTemplateData || {}

  const handleSelectedVariantChange = (key, variant) => {
    setSelectedVariants(prevState => ({
      ...prevState,
      [key]: variant
    }))
  }

  const selectedVariantsArray = useMemo(() => Object.values(selectedVariants)?.filter(v => v?.availableForSale), [selectedVariants])

  useEffect(() => {
    const loadProducts = async () => {
      try {
        const bundleProducts = bundle?.trigger?.[0]?.productList
        const products = await getGroupedProducts({
          firstImages: 1, firstVariants: 20, products: bundleProducts?.map(product => ({
            handle: product?.shopify?.shopifyHandle,
            tags: JSON.parse(bundleProducts?.[0]?.shopify?.shopifyRaw)?.tags
          }))
        })

        const currencyCode = checkout.currencyCode || "AUD"
        const price = products?.reduce((total, p) => total + Number(p?.product?.variants?.[0]?.presentmentPrices?.find(
          item => item?.price?.currencyCode === currencyCode,
        )?.price?.amount), 0)
        const hasDiscount = bundle?.action?.[0]?._type === "promotionActionAllTriggeredProductsDiscount" && bundle?.action?.[0]?.type === "amountTo"

        setProducts(products)
        if (price) {
          setFormattedPrice(price.toFixed(2))
        }
        if (hasDiscount) {
          const discount = bundle?.action?.[0]?.value
          setFormattedDiscountedPrice(discount?.toFixed(2))
          setSalePercentage(((1 - Number(discount) / Number(price)) * 100).toFixed(0))
        }
      } catch (e) {
        // ignored
      }
    }

    loadProducts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bundle])

  const handleAddToCart = useCallback(async() => {
    await addAllToCart(products?.map((p, i) => ({
      product: p?.product,
      variant: selectedVariants?.[i.toString()]
    })))
  }, [addAllToCart, products, selectedVariants])

  const selectionRemaining = selectedVariantsArray?.length < bundle?.trigger?.[0]?.productList?.length

  Component.displayName = name
  return (
    <Component
      bundle={bundle}
      products={products}
      discount={bundle?.action?.[0]?.value}
      formattedPrice={formattedPrice}
      formattedDiscountedPrice={formattedDiscountedPrice}
      salePercentage={salePercentage}
      descriptionHtml={descriptionHtml}
      shippingLink={settingsShippingLink}
      selectionRemaining={selectionRemaining}
      cartLoading={loading}
      subtitle={bundleSubtitle?.replace("{discount}", salePercentage ? `${salePercentage}%` : "")}
      productsTitle={bundleProductsTitle}
      addText={selectionRemaining ? productTemplateData?.selectSizesButtonText : productTemplateData?.addToCartButtonText}
      handleSelectedVariantChange={handleSelectedVariantChange}
      handleAddToCart={handleAddToCart}
    />
  )
}
