import tw, { css, styled } from "twin.macro"

export const Wrapper = tw.div`
`

export const RowWrapper = tw.div`
  flex flex-col lg:flex-row
`

export const ImageWrapper = styled.div`
  ${css`
    width: 140px;
    @media screen and (max-width: 1280px) {
      width: 100%;
    }
  `}
`

export const InfoWrapper = tw.div`
  lg:ml-1-6 flex-1 mt-1 lg:mt-0
`

export const ProductTitle = tw.div`
  text-14 font-semibold leading-1.25
`

export const Divider = tw.hr`
  my-2 border-t border-black border-opacity-20
`
