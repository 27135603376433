import React from "react"

import { withDetailSwatches } from "./withDetailSwatches"
import { Outer, Inner, Text, StyledSwatch } from "./DetailSwatchesStyles"

export const DetailSwatches = withDetailSwatches(
  ({ type, swatches, handle: selectedHandle, colour, handleItemClick }) => (
    <Outer type={type}>
      <Inner type={type}>
        {swatches?.map(({ id, title, handle, image, url }, index) => (
          <StyledSwatch
            key={id}
            title={title}
            image={image}
            active={selectedHandle === handle}
            {...(handleItemClick ? { onClick: () => handleItemClick(index) } : { url })}
          />
        ))}
      </Inner>
      {colour ? <Text>{colour}</Text> : null}
    </Outer>
  ),
)
