import tw, { styled, css } from "twin.macro"
import { ButtonLink } from "../../../ButtonLink/ButtonLink"

export const Buttons = styled.div`
  ${tw`flex flex-row -mx-0-8 items-center`}
  ${({ marginBottom }) => marginBottom && tw`mb-2`}
  ${({ align }) => {
    if (align === "left") return tw`justify-start`
    if (align === "right") return tw`md:justify-end`
    return tw`justify-center`
  }}
  ${({ buttonMarginTop }) => {
    return buttonMarginTop
      ? css`
          margin-top: ${buttonMarginTop}%;
        `
      : null
  }}
`

export const Button = styled(ButtonLink)`
  ${tw`mx-0-8 whitespace-nowrap`}
  ${({ buttonsPadding }) => {
    return css`
      margin-left: ${buttonsPadding}px;
      margin-right: ${buttonsPadding}px;
    `
  }}
`
