import React from "react"
import { graphql, useStaticQuery } from "gatsby"

export const withRelated = Component => ({
  name = "Related",
  recommendations,
  relatedProducts,
  relatedCollection,
}) => {
  const { productTemplateData } = useStaticQuery(graphql`
    query SANITY_TEMPLATE_PRODUCT_RELATED {
      productTemplateData: sanityTemplateProduct {
        additionalRelatedProductsTitle
      }
    }
  `)

  const { additionalRelatedProductsTitle } = productTemplateData || {}

  // Check that there is no parent/children double up by checking the parent tag against the handles
  const uniqueRecommendations = recommendations && recommendations
  .filter(product => !recommendations.some(recommendation => `parent:${recommendation.handle}` === product.tags.find(tag => tag.startsWith('parent:'))))
  
  Component.displayName = name
  return (
    <Component
      title={additionalRelatedProductsTitle}
      recommendations={uniqueRecommendations}
      relatedProducts={relatedProducts}
      relatedCollection={relatedCollection}
    />
  )
}
