import React, { useMemo } from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useApp } from "../../../hooks/useApp"
import { useImage } from "../../../hooks/useImage"
import { getAdditionalText } from "../../../utils/getAdditionalText"

const getFeatures = (tags, features) => {
  const tempArray = []
  features.forEach(feature => {
    tags.forEach(tag => {
      if (feature.title.toLowerCase() === tag) {
        tempArray.push(feature)
      }
    })
  })
  return tempArray
}

const getMetafield = (key, metafields) => {
  return metafields?.find(metafield => metafield.key === key)?.value
}

export const withDetail =
  Component =>
  ({ name = "Detail", description, tags, metafields, vendor }) => {
    const {
      config: {
        settings: {
          constraints: { CARE, DETAILS },
          tags: { feature, productCode: productCodeTag },
        },
      },
    } = useApp()

    const {
      template: {
        product: { additionalProductCodeTitleText },
      },
    } = getAdditionalText()

    const { productTemplateData } = useStaticQuery(graphql`
      query SANITY_TEMPLATE_PRODUCT_INFORMATION {
        productTemplateData: sanityTemplateProduct {
          additionalProductDescriptionText
          additionalProductDetailsText
          additionalProductCareText
          additionalProductFeaturesText
          settingProductFeatures: _rawSettingProductFeatures(
            resolveReferences: { maxDepth: 3 }
          )
          settingsShippingLink: _rawSettingsShippingLink(
            resolveReferences: { maxDepth: 2 }
          )
          settingsShippingAccordion: _rawSettingsShippingAccordion(
            resolveReferences: { maxDepth: 2 }
          )
          settingsOptionalContent: _rawSettingsOptionalContent(
            resolveReferences: { maxDepth: 2 }
          )
        }
      }
    `)

    const {
      additionalProductDescriptionText,
      additionalProductDetailsText,
      additionalProductCareText,
      additionalProductFeaturesText,
      settingProductFeatures,
      settingsShippingLink,
      settingsShippingAccordion,
      settingsOptionalContent,
    } = productTemplateData || {}

    const { getGatsbyImage } = useImage()

    const featureTags = tags
      ?.filter(tag => tag.includes(feature))
      ?.map(tag => tag.split(":")?.[1]?.toLowerCase())

    const productFeatures = getFeatures(
      featureTags || [],
      settingProductFeatures || [],
    )?.map(feature => ({
      ...feature,
      icon: getGatsbyImage(feature.icon),
    }))

    const productCare = getMetafield(CARE, metafields)
    const productDetail = getMetafield(DETAILS, metafields)

    const productCode = tags
      .find(tag => tag.includes(productCodeTag))
      ?.split(":")?.[1]

    const tabs = [
      {
        title: additionalProductDescriptionText,
        content: description,
        type: "Description",
      },
      {
        title: additionalProductDetailsText,
        content: productDetail,
        type: "RichText",
      },
      {
        title: additionalProductCareText,
        content: productCare,
        type: "RichText",
      },
      {
        title: additionalProductFeaturesText,
        content: productFeatures,
        type: "Feature",
      },
    ].filter(
      ({ title, content }) =>
        Boolean(title?.length > 0) && Boolean(content?.length > 0),
    )

    const accordions = [
      ...tabs,
      {
        title: settingsShippingAccordion?.question,
        content: settingsShippingAccordion?.answer,
        type: "SanityRichText",
      },
    ].filter(
      ({ title, content }) =>
        Boolean(title?.length > 0) && Boolean(content?.length > 0),
    )

    const vendorText = useMemo(() => {
      if(!vendor) return null
      if(vendor == "Drizabone") return `Also available on <a href="https://www.drizabone.com.au" target="_blank">www.drizabone.com.au</a>`
      if(vendor == "Rossi Boots") return `Also available on <a href="https://www.rossiboots.com.au" target="_blank">www.rossiboots.com.au</a>`
    }, [vendor])

    Component.displayName = name
    return (
      <Component
        tabs={tabs}
        accordions={accordions}
        link={settingsShippingLink}
        optionalContent={settingsOptionalContent}
        productCode={productCode}
        additionalProductCodeTitleText={additionalProductCodeTitleText}
        vendorText={vendorText}
      />
    )
  }
