import React, { useEffect } from "react"
import { graphql } from "gatsby"

import { useCore } from "../hooks/useCore"
import { useShopify } from "../hooks/useShopify"
import { useQueries } from "../hooks/useQueries"
import { useApp } from "../hooks/useApp"
import { Product as Template } from "../components/Product/Product"
import { useLocation } from "../hooks/useLocation"

export const query = graphql`
  query ($handle: String!) {
    product: sanityProduct(shopify: { shopifyHandle: { eq: $handle } }) {
      ...GatsbySanityProductFragment
      bundle: _rawBundle(resolveReferences: { maxDepth: 6 })
      bundleSubtitle
      bundleProductsTitle
      siblings: _rawSiblings(resolveReferences: { maxDepth: 2 })
    }
    content: allSanityProduct(
      filter: { shopify: { shopifyHandle: { eq: $handle } } }
      sort: { order: DESC, fields: _updatedAt }
    ) {
      edges {
        node {
          metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
          related: _rawRelated(resolveReferences: { maxDepth: 3 })
          primaryCollection {
            title
            shopify {
              shopifyHandle
              shopifyRaw
            }
          }
          collection {
            shopify {
              shopifyHandle
            }
          }
          productAdditionalSections: _rawAdditionalSections(
            resolveReferences: { maxDepth: 8 }
          )
          manualOrder {
            _key
            _type
            enabled: manualOrderEnabled
            enableAddToCart
            hideFindAStoreButton
            description: _rawManualOrderDescriptionHtml(
              resolveReferences: { maxDepth: 4 }
            )
            buttons: _rawButtons
          }
        }
      }
    }
    template: sanityTemplateProduct {
      settingShowRelatedProducts
      templateAdditionalSections: _rawAdditionalSections(
        resolveReferences: { maxDepth: 8 }
      )
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
    }
  }
`

const Component = ({ data, ...props }): JSX.Element => {
  const {
    helpers: { encodeShopifyId, isBrowser },
  } = useCore()

  const {
    queries: { GET_PRODUCT_COMPLETE },
  } = useQueries()

  const {
    config: {
      settings: {
        tags: { productCode },
        constraints: { productMetafields },
      },
    },
  } = useApp()

  const { useLazyQuery } = useShopify()

  const {
    product: {
      shopify: { id, handle },
      tags,
      siblings,
    },
  } = data

  const { location } = useLocation()

  const [getProduct, { data: live, called, loading, error }] = useLazyQuery(
    GET_PRODUCT_COMPLETE,
    {
      fetchPolicy: `cache-and-network`,
      nextFetchPolicy: "cache-first",
      variables: {
        id: encodeShopifyId(id, "Product"),
        countryCode: location,
        handle,
        parentQuery: `tag:'${tags?.find(tag =>
          tag.includes(productCode),
        )}' -tag:'parent' -tag:'flow:unpublished'`,
        firstImages: 15,
        firstMedia: 15,
        firstVariants: 30,
        firstCollections: 1,
        metafieldIdentifiers: productMetafields,
      },
    },
  )

  useEffect(() => {
    if (!handle || called) return
    if (isBrowser) getProduct()
  }, [handle, called, isBrowser, getProduct])

  if (error) console.error(error)

  return (
    <Template
      {...props}
      {...data}
      live={live}
      loading={loading}
      siblings={siblings}
    />
  )
}
export default Component
