import tw, { styled } from "twin.macro"

export const EmphasisText = tw.div`
  text-16 font-semibold leading-1.78 mt-1 mb-1
`

export const RichText = styled.div`
  ${tw`text-14 leading-1.78 mt-4`}
  > *, > div > * {
    ${tw`mb-0-8`}
  }
  li {
    ${tw`mt-1`}
  }
`

export const ProductsWrapper = tw.div`
  mt-5 mb-3
`

export const PriceWrapper = tw.div`
  flex
`

export const PriceOriginal = tw.div`
  text-16 font-semibold leading-1.78 line-through mr-0-8 text-grey
`

export const Price = tw.div`
  text-16 font-semibold leading-1.78
`

export const SalePercentage = tw.div`
  text-12 leading-1.78 mb-1
`
