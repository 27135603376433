import React, { useCallback } from "react"

import { useApp } from "../../../../hooks/useApp"

export const withDetailSwatches = Component => ({
  name = "DetailSwatches",
  type = "product",
  siblings,
  handle,
  selectOptions = [],
  defaultSelectedOption = [],
  handleItemClick = null
}) => {
  const {
    config: {
      settings: {
        routes: { PRODUCT },
        tags: { swatchColor },
        constraints: { COLOUR },
      },
    },
  } = useApp()

  const getSwatchColour = useCallback(
    tags => {
      const found = tags.find(tag => {
        const ret = tag.includes(swatchColor)
        return ret
      })
      const split = found?.split(":")
      const ret = split?.[split?.length-1]
      return ret?.replace(/\//g, "")
    },
    [swatchColor],
  )


  const swatches = siblings?.map(({ id, title, tags, handle, shopify }) => {
    return {
      id,
      title,
      handle: shopify?.shopifyHandle || handle,
      url: `${PRODUCT}/${shopify?.shopifyHandle || handle}`,
      image: `https://res.cloudinary.com/propel-group/RB%20SELLARS%20Swatch/${getSwatchColour(
        tags,
      )}`,
    }
  })

  const selectedColour =
    selectOptions?.find(option => option.name === COLOUR)?.value ||
    defaultSelectedOption?.find(option => option.name === COLOUR)?.value

  Component.displayName = name
  return (
    <Component type={type} swatches={swatches} colour={selectedColour} handle={handle} handleItemClick={handleItemClick} />
  )
}
