import React from "react"

import { DesktopImages } from "./Desktop/DesktopImages"
import { MobileImages } from "./Mobile/MobileImages"

export const Images = ({ media }) => (
  <>
    <DesktopImages media={media} />
    <MobileImages media={media} />
  </>
)
