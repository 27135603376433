import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useApp } from "../../../hooks/useApp"

const fixedEncodeURIComponent = str => {
  return encodeURIComponent(str).replace(/[!'()*]/g, function (c) {
    return "%" + c.charCodeAt(0).toString(16)
  })
}

export const withShare = Component => ({ name = "Share", handle, image }) => {
  const {
    config: {
      settings: { routes },
      app: { url },
    },
  } = useApp()

  const { productTemplateData } = useStaticQuery(graphql`
    query SANITY_TEMPLATE_PRODUCT_SOCIAL_SHARE {
      productTemplateData: sanityTemplateProduct {
        settingSocialShareMethods {
          name
          _key
        }
        additionalSocialShareText
        additionalSocialShareSubject
      }
    }
  `)

  const {
    settingSocialShareMethods,
    additionalSocialShareText,
    additionalSocialShareSubject,
  } = productTemplateData || {}

  const handleClick = method => {
    const encodedUrl = fixedEncodeURIComponent(
      `${url}${routes.PRODUCT}/${handle}`,
    )
    const encodedSubject = fixedEncodeURIComponent(additionalSocialShareSubject)
    const encodedImageUrl = fixedEncodeURIComponent(image)

    switch (method) {
      case "mail":
        window.open(
          `mailto:?subject=${encodedSubject}&body=${encodedUrl}`,
          "_blank",
        )
        break
      case "facebook":
        window.open(
          `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`,
          "facebook-popup",
          "height=400,width=600",
        )
        break
      case "twitter":
        window.open(
          `https://twitter.com/intent/tweet?text=${encodedSubject}%20${encodedUrl}`,
        )
        break
      case "pinterest":
        window.open(
          `http://pinterest.com/pin/create/button/?url=${encodedUrl}&media=${encodedImageUrl}`,
        )
        break
      case "whatsapp":
        window.open(
          `https://api.whatsapp.com/send?text=${encodedSubject}%20${encodedUrl}`,
        )
        break
      default:
        break
    }
  }

  Component.displayName = name
  return settingSocialShareMethods?.length > 0 ? (
    <Component
      methods={settingSocialShareMethods}
      title={additionalSocialShareText}
      handleClick={handleClick}
    />
  ) : null
}
