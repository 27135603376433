import React from "react"
import { Script } from "gatsby"

import { withFindInStore } from "./withFindInStore"
import { BrauzConfig } from "./FindInStoreStyles"

export const FindInStore = withFindInStore(({ domain, brauzEndpoint }) => (
  <>
    <BrauzConfig
      id="brauz-find-in-store-config-product-page"
      data-sd={domain}
    />
    <div id="brauz-find-in-store-product-page"></div>
    {brauzEndpoint && <Script strategy="post-hydrate" src={brauzEndpoint} />}
  </>
))
