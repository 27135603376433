
import React from "react"
import { withReviewsWidget } from "./withReviewsWidget"

export const ReviewsWidget = withReviewsWidget(
  ({ productId, widgetContainer }): JSX.Element => (
    <div className="px-2 md:px-0">
      <div ref={widgetContainer} data-oke-widget data-oke-reviews-product-id={`shopify-${productId}`}></div>
    </div>
  )
)