import React from "react"
import { withBundleForm } from "./withBundleForm"
import {
  EmphasisText,
  Price,
  PriceOriginal,
  PriceWrapper,
  ProductsWrapper,
  RichText,
  SalePercentage
} from "./BundleStyles"
import { Shipping } from "../../Detail/DetailStyles"
import { ButtonLink } from "../../../ButtonLink/ButtonLink"
import { BundleProduct } from "./BundleProduct/BundleProduct"
import { AdditionalPayments } from "../../AdditionalPayments/AdditionalPayments"
import { StyledButton } from "../../../Styled/Button"

export const BundleForm = withBundleForm(
  ({
     descriptionHtml,
     products,
     discount,
     formattedPrice,
     formattedDiscountedPrice,
     salePercentage,
     shippingLink,
     selectionRemaining,
     cartLoading,
     subtitle,
     productsTitle,
     addText,
     handleSelectedVariantChange,
     handleAddToCart
   }) => (
    <div>
      {subtitle?.length > 0 ? (
        <EmphasisText>{subtitle}</EmphasisText>
      ) : null}
      <RichText
        dangerouslySetInnerHTML={{ __html: descriptionHtml }}
      />
      {products && (
        <>
          <ProductsWrapper>
            {productsTitle?.length > 0 ? (
              <EmphasisText>{productsTitle}</EmphasisText>
            ) : null}
            {products?.map((product, index) => (
              <BundleProduct key={index} product={product}
                             handleSelectedVariantChange={variant => handleSelectedVariantChange?.(index, variant)} />
            ))}
            {formattedPrice && (
              <>
                <PriceWrapper>
                  <PriceOriginal>${formattedPrice}</PriceOriginal>
                  <Price>${formattedDiscountedPrice}</Price>
                </PriceWrapper>
                <SalePercentage>({salePercentage}% OFF)</SalePercentage>
              </>
            )}
            <AdditionalPayments overridePrice={discount} />
            <StyledButton wide size="primary" disabled={selectionRemaining || cartLoading} onClick={handleAddToCart}>
              {addText}
            </StyledButton>
          </ProductsWrapper>
          <Shipping>
            <ButtonLink link={shippingLink} style="underlinedLinkNavy" />
          </Shipping>
        </>
      )}
    </div>
  ),
)
