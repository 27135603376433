import React from "react"

import { withDetail } from "./withDetail"
import {
  Wrapper,
  Desktop,
  Mobile,
  StyledTabs,
  StyledTabList,
  StyledTab,
  StyledTabPanel,
  RichText,
  Feature,
  IconWrapper,
  Shipping,
  OptionalContent,
  ProductCode,
  ProductVendorDescription,
} from "./DetailStyles"
import { Image } from "../../Image/Image"

import { TabPanels } from "@reach/tabs"
import { SmallBody } from "../../Styled/Text"
import { Accordion } from "../../Accordion/Accordion"
import { RichText as SanityRichText } from "../../RichText/RichText"
import { ButtonLink } from "../../ButtonLink/ButtonLink"

export const Detail = withDetail(
  ({
    tabs,
    accordions,
    link,
    optionalContent,
    productCode,
    additionalProductCodeTitleText,
    vendorText
  }) => (
    <Wrapper>
      <Desktop>
        <StyledTabs>
          {({ selectedIndex }) => (
            <div>
              <StyledTabList>
                {tabs.map((tab, index) => (
                  <StyledTab
                    isSelected={selectedIndex === index}
                    key={tab.title}
                  >
                    {tab.title}
                  </StyledTab>
                ))}
              </StyledTabList>
              <TabPanels>
                {tabs.map(tab => (
                  <StyledTabPanel key={tab.title}>
                    {tab.type === "Description" && (
                      <div>
                        <ProductCode>
                          {additionalProductCodeTitleText}: {productCode}
                        </ProductCode>
                        <RichText
                          dangerouslySetInnerHTML={{ __html: tab.content }}
                        />
                        {vendorText && (
                          <ProductVendorDescription
                            dangerouslySetInnerHTML={{ __html: vendorText }}
                          />
                        )}
                      </div>
                    )}
                    {tab.type === "RichText" && (
                      <RichText
                        dangerouslySetInnerHTML={{ __html: tab.content }}
                      />
                    )}
                    {tab.type === "Feature" && (
                      <div>
                        {tab.content.map(feature => (
                          <Feature key={feature.title}>
                            <IconWrapper>
                              <Image image={feature.icon} ratio={"1/1"} />
                            </IconWrapper>
                            <SmallBody>{feature.title}</SmallBody>
                          </Feature>
                        ))}
                      </div>
                    )}
                  </StyledTabPanel>
                ))}
              </TabPanels>
            </div>
          )}
        </StyledTabs>
        <Shipping>
          <ButtonLink link={link} style="underlinedLinkNavy" />
        </Shipping>
        <OptionalContent>{optionalContent}</OptionalContent>
      </Desktop>
      <Mobile>
        {accordions.map(accordion => (
          <Accordion
            key={accordion.title}
            layout={"info"}
            title={accordion.title}
          >
            {accordion.type === "Description" && (
              <div>
                <ProductCode>
                  {additionalProductCodeTitleText}: {productCode}
                </ProductCode>
                <RichText
                  dangerouslySetInnerHTML={{ __html: accordion.content }}
                />
                {vendorText && (
                  <ProductVendorDescription
                    dangerouslySetInnerHTML={{ __html: vendorText }}
                  />
                )}                
              </div>
            )}
            {accordion.type === "RichText" && (
              <RichText
                dangerouslySetInnerHTML={{ __html: accordion.content }}
              />
            )}
            {accordion.type === "Feature" && (
              <div>
                {accordion.content.map(feature => (
                  <Feature key={feature.title}>
                    <IconWrapper>
                      <Image image={feature.icon} ratio={"1/1"} />
                    </IconWrapper>
                    <SmallBody>{feature.title}</SmallBody>
                  </Feature>
                ))}
              </div>
            )}
            {accordion.type === "SanityRichText" && (
              <SanityRichText>{accordion.content}</SanityRichText>
            )}
          </Accordion>
        ))}
      </Mobile>
    </Wrapper>
  ),
)
