import React, { useMemo } from "react"
import { useShopifyVariants } from "../../../../hooks/useShopify"
import { useLocation } from "../../../../hooks/useLocation"

export const withDetailForm =
  Component =>
  ({
    name = "Form",
    product,
    siblings,
    loading,
    manualOrder,
    descriptionHtml,
    bundle,
    bundleSubtitle,
    bundleProductsTitle,
  }) => {
    const { tags, title, handle, variants } = product
    const { location } = useLocation()

    const showFindInStore = useMemo(() => {
      return location === "AU" ? true : false
    }, [location])

    const { activeVariant, selectedOptions, handleVariant } =
      useShopifyVariants({
        useParameter: true,
        product,
        loading,
      })
    const multipleVariants = variants.length > 1

    const currentVariant = activeVariant ?? variants?.[0]

    const isOnSale =
      Number(
        currentVariant?.compareAtPriceV2?.amount ||
          currentVariant?.compareAtPriceV2 ||
          0,
      ) >
      Number(currentVariant?.priceV2?.amount || currentVariant?.priceV2 || 0)

    const enableAddToCart = manualOrder?.enableAddToCart
    let showAddToCart = true

    if (manualOrder?.enabled) {
      showAddToCart = !!enableAddToCart
    } else {
      showAddToCart = true
    }

    const price = Number(
      activeVariant?.priceV2 ||
        variants?.[0]?.priceV2?.amount ||
        variants?.[0]?.priceV2 ||
        0,
    )

    Component.displayName = name

    return (
      <Component
        tags={tags}
        bundle={bundle}
        bundleSubtitle={bundleSubtitle}
        bundleProductsTitle={bundleProductsTitle}
        title={title}
        product={product}
        selectedVariant={activeVariant}
        defaultVariant={variants?.[0]}
        loading={loading}
        siblings={siblings}
        selectedOptions={selectedOptions}
        handle={handle}
        multipleVariants={multipleVariants}
        handleVariant={handleVariant}
        isOnSale={isOnSale}
        manualOrder={manualOrder}
        descriptionHtml={descriptionHtml}
        showAddToCart={showAddToCart}
        showFindInStore={showFindInStore}
        price={price}
      />
    )
  }
