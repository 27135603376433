import React, { useEffect, useRef } from "react"
import { useCore } from "../../hooks/useCore"


export const withReviewsWidget = Component => ({ name = "ReviewsWidget", productId }) => {
  const widgetContainer = useRef(null)

  const initialiseReviewsWidget = () => {
    window.okeWidgetApi.initWidget(widgetContainer.current)
  }
  
  let interval

  const {
    helpers: { decodeShopifyId },
  } = useCore()

  const decodeAlternate = (string) => {
    const decoded = decodeShopifyId(string, 'Product')
    if (!decoded && typeof Buffer !== undefined) {
      return Buffer.from(string, 'base64').toString().split('Product/')[1]
    } else {
      return decoded
    }
  }

  const id = !productId.includes('gid://') ? decodeAlternate(productId) : productId.replace("gid://shopify/Product/", "")
  
  useEffect(() => {
    let attempts = 0
    clearInterval(interval)
    interval = setInterval(() => {
      attempts++
      if (window.okeWidgetApi && widgetContainer.current) {
        initialiseReviewsWidget()
        clearInterval(interval)
      } else if (attempts > 30) {
        clearInterval(interval)
      }
    }, 500)
  }, [])


  Component.displayName = name
  return <Component productId={id} widgetContainer={widgetContainer} />
}
