import React from "react"

import { withProduct } from "./withProduct"
import {
  Outer,
  Inner,
  ImagesWrapper,
  DetailsWrapper,
  Sticky,
  Divider,
} from "./ProductStyles"
import { Images } from "./Images/Images"
import { DetailForm as Form } from "./Form/Detail/DetailForm"
import { Detail } from "./Detail/Detail"
import { Share } from "./Share/Share"
import { Related } from "./Related/Related"
import { Sections } from "../Sections/Sections"
import { BreadCrumbs } from "../BreadCrumbs/BreadCrumbs"
import { ReviewsWidget } from "../Okendo/ReviewsWidget"

export const Product = withProduct(
  ({
    product,
    siblings,
    bundle,
    bundleSubtitle,
    bundleProductsTitle,
    product: {
      handle,
      descriptionHtml,
      tags,
      image,
      metafields,
      media,
      options,
      id,
      vendor,
    },
    loading,
    settingShowRelatedProducts,
    relatedProducts,
    relatedCollection,
    recommendations,
    productAdditionalSections,
    templateAdditionalSections,
    paths,
    manualOrder,
  }): JSX.Element => {
    try {
      if (
        window?.location?.pathname?.includes(
          "products/spring-summer-23-catalogue-s23cata",
        )
      ) {
        window.location.href = "/collections/new-arrivals"
      }
    } catch {
      // nothing
    }

    return (
      <Outer>
        {paths.length > 0 && <BreadCrumbs paths={paths} />}
        <Inner>
          <ImagesWrapper>
            <Images media={media} />
          </ImagesWrapper>
          <DetailsWrapper>
            <Sticky>
              {options?.length > 0 ? (
                <Form
                  product={product}
                  siblings={siblings}
                  loading={loading}
                  descriptionHtml={descriptionHtml}
                  manualOrder={manualOrder}
                  bundle={bundle}
                  bundleSubtitle={bundleSubtitle}
                  bundleProductsTitle={bundleProductsTitle}
                />
              ) : null}
              {!bundle && (
                <Detail
                  description={descriptionHtml}
                  metafields={metafields}
                  tags={tags}
                  vendor={vendor}
                />
              )}
              <Share handle={handle} image={image} />
            </Sticky>
          </DetailsWrapper>
        </Inner>
        <ReviewsWidget productId={id} />
        <Divider />
        <Sections page={{ content: productAdditionalSections }} />
        <Sections page={{ content: templateAdditionalSections }} />
        {settingShowRelatedProducts ? (
          <Related
            recommendations={recommendations}
            relatedProducts={relatedProducts}
            relatedCollection={relatedCollection}
          />
        ) : null}
      </Outer>
    )
  },
)
