import React from "react"
import { withBundleProduct } from "./withBundleProduct"
import { Divider, ImageWrapper, InfoWrapper, ProductTitle, RowWrapper, Wrapper } from "./BundleProductStyles"
import { Image } from "../../../../Image/Image"
import { Link } from "gatsby"
import { DetailSwatches as Swatches } from "../../../Swatches/Detail/DetailSwatches"
import { Accordion } from "../../../../Accordion/Accordion"
import { Variants } from "../../../Variants/Variants"

export const BundleProduct = withBundleProduct(
  ({
     product,
     productUrl,
     siblings,
     selectedSibling,
     setSelectedSibling,
     selectedOption,
     setSelectedOption,
   }) => (
    <Wrapper>
      <RowWrapper>
        <ImageWrapper as={Link} to={productUrl}>
          <Image
            image={selectedSibling?.images?.[0]}
            ratio={"15/19"}
          />
        </ImageWrapper>
        <InfoWrapper>
          <ProductTitle as={Link} to={productUrl}>
            {product?.title}
          </ProductTitle>
          <Swatches
            type="bundle"
            siblings={siblings?.length > 0 ? siblings : [product]}
            selectOptions={selectedSibling?.options?.map(option => ({ name: option?.name, value: option?.values?.[0] }))}
            handle={selectedSibling?.handle}
            handleItemClick={(index) => setSelectedSibling(siblings?.[index])}
          />
          <Accordion layout="bundleSize" title="Select Size">
            <Variants
              product={selectedSibling}
              selectedOptions={selectedOption ? [selectedOption] : null}
              handleVariant={(variant) => setSelectedOption(variant)}
            />
          </Accordion>
        </InfoWrapper>
      </RowWrapper>
      <Divider />
    </Wrapper>
  ),
)
