import tw, { styled } from "twin.macro"
import { StyledContainer } from "../Styled/Container"

export const Outer = tw.div``

export const Inner = styled(StyledContainer)`
  ${tw`flex flex-col md:flex-row md:pb-8 relative max-w-full`}
`

export const ImagesWrapper = tw.div`w-full md:w-1/2 mlg:w-6/10 md:flex-1 mb-2-4 md:mb-0`

export const DetailsWrapper = tw.div`w-full md:min-w-36 md:w-1/2 mlg:w-4/10 md:px-3`

export const Sticky = tw.div`
  md:sticky md:top-18
`

export const Divider = tw.hr`
  md:hidden text-navy text-opacity-60
`
